/* You can add global styles to this file, and also import other style files */

html {
  height: 100%;
  margin: 0px;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

app-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-content: stretch;
}

app-home {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
}

app-browse-view {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

  a.white {
    color: #ffffff;
  }

code {
  color: #e01a76;
}


#measure-widget-container {
  background: #fff;
  padding: 10px;
}


.action-button {
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #d3d3d3;
  color: #6e6e6e;
  height: 32px;
  width: 32px;
  text-align: center;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
}

.action-button:hover {
  background: #0079c1;
  color: #e4e4e4;
}


.stations-dialog-container .mat-dialog-container {
  padding: 0px !important;
  overflow: hidden;
  display: flex;
}

.agreeDiv {
  text-align: center;
}

.centerDialogActions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.paragraphDiv {
  padding: 8px;
}

.dialogbody {
  padding: 16px;
}

.titleDiv {
  display: inline-block;
  background-image: url(assets/horizontalTexNetLogoWhite.png);
  background-size: cover;
}

.mat-dialog-actions {
    margin: 0px !important;
}


.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.navbar {
  padding: 0px;
}

.toolbar-spacer {
  flex: 1 1 auto;
  text-align: center;
}

.toolbar-center-spacer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

ul.custom-bread-crumb {
  display: inline-flex;
  padding: 0px !important;
  background-color: none !important;
  margin: 0px !important;
}

main {
  padding: 10px;
}

.warning-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 15px;
}

.horiz-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.horiz-row-member {
  margin: 25px;
}

.horiz-row-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fluid-fraction-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.fluid-fraction-input {
  margin: 5px;
}

.filler {
  flex: 1 1 auto;
}

.injection-data-list-header-cell {
  padding: 5px !important;
}

.injection-data-list-table-body-cell {
  padding: 5px !important;
  text-align: center !important;
}

.well-details {
}

.well-details-items {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.well-details-item {
  padding: 10px;
}

.well-details-label {
  display: block;
  text-align: left;
  font-size: 0.9em;
  color: #888888;
}

.well-details-value {
  display: block;
  text-align: right;
}

.import-excel-data-container {
  min-width: 300px;
}

.no-data-row {
  display: flex;
  justify-content: center;
  width: 100%;
}

.form-row-container {
  margin: 10px;
}

.field-row-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.field-row-item {
  margin: 10px;
}

mat-form-field {
  min-width: 200px;
}

.centered-content-container {
  max-width: 1000px;
}

.two-column {
  min-width: 400px;
}

.toolbar-two-line {
  font-size: 0.6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 17px;
}

.centered-content-column {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.container-card {
  width: 1200px;
  margin: 15px;
}

.help-container {
  max-width: 1200px;
  margin: 15px;
}

td.mat-cell {
  padding: 5px !important;
}

.browse-well-details-table {
  width: 100%;
}

  .browse-well-details-table tr:nth-child(event) {
    background-color: #dddddd;
  }

  .browse-well-details-table tr:nth-child(odd) {
    background-color: #eeeeee;
  }

  .browse-well-details-table .label-cell {
    font-weight: bold;
  }

  .browse-well-details-table td {
    padding: 3px;
  }

.documentation-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.documentation-card {
  max-width: 1200px;
  margin: 10px;
}

.card-container {
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  padding: 20px 6px 12px 12px;
  border-bottom: 2px solid #c1c1c1;
}

.card-content {
  display: flex;
  flex-direction: column;
}

h3 {
  font-size: 22px;
}

.export-icon-container {
  width: 100%;
  height: 0;
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 24px;
  right: 6px;
  padding-bottom: 20px;
  color: #2a4f9d;
}

@-moz-document url-prefix() {
  #export-icon {
    right: 16px !important;
  }
}

#export-icon mat-icon {
  font-size: 32px;
  cursor: pointer;
}


/* Make the layer toggles in the ESRI parts of the UI be checkboxes.  Like, you know, every other map UI. */
.esri-icon-non-visible::before {
  content: "\e610" !important;
}

.esri-icon-visible::before {
  content: "\e611" !important;
}

.table-header-column-layout {
  display: flex;
  flex-direction: column;
}

.map-container {
  flex-grow: 2;
}

.browse-view-container {
  flex-grow: 2;
}

.browse-view-content {
  display: flex !important;
  flex-direction: column;
}

.sidenav-contents {
  width: 300px;
}

.split-pane-content-primary {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.split-pane-content-secondary {
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.logo-img-container {
  margin-right: 10px;
}

@media (min-width: 1920px) {
  .logo-img {
    width: 197px;
    height: 44px;
  }
}

@media (max-width: 1920px) {

  .logo-img {
    width: 161px;
    height: 36px;
  }

  h1.title-heading {
    font-size: 1.2em !important;
  }
}

